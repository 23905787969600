
  import Vue from 'vue';
  import PwaTheme from '@/components/layout/PwaTheme.vue';
  import PwaLoader from '@/components/common/PwaLoader.vue';
  import PwaModals from "@/components/modals/PwaModals.vue";
  import {PortalTarget} from 'portal-vue';
  import {AuthActionTypes} from "@/store/modules/auth/actions";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import {AppActionTypes} from "@/store/modules/app/actions";
  import {canNotify, getToken} from "@/services/firebase";
  import {debounce, getDevice, isPrerender, loadScript} from "@/utils/common";
  import {
    onConnectionChanged,
    isChatConnected,
    onUserLastMessagesChange,
    Unsubscribe,
    onUserCompetitionsLastMessagesChange
  } from "@/services/chat";
  import {getUrlUserSourceParams} from "@/utils/user-source";
  import {UserSourceActionTypes} from "@/store/modules/userSource/actions";
  import {NotificationCenterActionTypes} from "@/store/modules/notificationCenter/actions";
  import {initGoogleLogin} from "@/services/google";
  import {EVENTS} from "@/utils/events";
  import {SOCIAL_LOGIN_PLATFORMS} from "@/utils/social-login";
  import { HIDE_NAVIGATION_PAGES, HIDE_GROUP_LOGO_PAGES, HIDE_TAB_BAR_PAGES } from "@/utils/page";
  import {loadCookieConsentScript, cookieConsentRun, hasUserDefaultCookieConsentValues, getCurrentCookieConsentValues, CONSENT_UPDATE_EVENT_NAME} from "@/utils/cookie-consent";
  import {
    isAndroidNativeApp,
    isIosNativeApp,
    isLegacyNativeApp,
    isNativeApp,
    TO_NATIVE_APP_EVENTS
  } from "@/utils/native-app";
  import nativeAppMixin from "@/mixins/nativeApp";
  import moment from 'moment-timezone';
  import {initWebAds} from "@/services/ads";
  import {ROUTES} from "@/router/routes";

  let lastMessagesUnsubscribe: Unsubscribe = null;
  let competitionsLastMessagesUnsubscribe: Unsubscribe = null;
  let notificationCenterInterval: any = null;
  let onChatConnectionChangedListener: any = null;
  const reloadTime = moment.utc().add(1, 'days');

  const mixins: any[] = [];
  if (isNativeApp || isLegacyNativeApp()) {
    mixins.push(nativeAppMixin);
  }

  export default Vue.extend({
    name: 'App',
    mixins,
    components: {
      PwaTheme,
      PwaLoader,
      PwaModals,
      PortalTarget,
      PwaBreadcrumb: () => import("@/components/common/PwaBreadcrumb.vue"),
      PwaHeader: () => import('@/components/layout/PwaHeader.vue'),
      PwaNavbarHeader: () => import('@/components/layout/PwaNavbarHeader.vue'),
      PwaSidebar: () => import('@/components/layout/PwaSidebar.vue'),
      PwaTabBar: () => import('@/components/layout/PwaTabBar.vue'),
      PwaSidebarMobile: () => import('@/components/layout/PwaSidebarMobile.vue'),
      PwaFooter: () => import('@/components/layout/PwaFooter.vue'),
      PwaTopBanner: () => import('@/components/ads/PwaTopBanner.vue'),
      PwaBottomBanner: () => import('@/components/ads/PwaBottomBanner.vue'),
      PwaWebDesktopLeaderboardBanner: () => import('@/components/ads/PwaWebDesktopLeaderboardBanner.vue'),
    },
    beforeMount(): void {
      if (!isPrerender) {
        loadCookieConsentScript()
          .then(() => {
            this.showCookieConsent();
            this.initCookieConsentInitialData();
          })
          .catch();
      }

      if (!this.isNativeApp) {
        if (!isPrerender) {
          if (process.env.VUE_APP_GOOGLE_CLIENT_ID) {
            loadScript('https://accounts.google.com/gsi/client', {defer: true}).catch();
          }

          if (this.hasAds) {
            initWebAds();
          }
        }
      } else {
        const nativeClasses = ['is-native-app'];
        if (isIosNativeApp) {
          nativeClasses.push('is-native-app-ios');
        } else if (isAndroidNativeApp) {
          nativeClasses.push('is-native-app-android');
        }

        document.documentElement.classList.add(...nativeClasses);
      }
    },
    mounted(): void {
      if (!this.isNativeApp) {
        initGoogleLogin(
          credential => this.$root.$emit(EVENTS.SOCIAL_LOGIN, {platform: SOCIAL_LOGIN_PLATFORMS.GOOGLE, data: credential})
        );
      }
      const userSourceParams = getUrlUserSourceParams(this.$route.query);
      if (userSourceParams && !this.$store.getters.hasUserSource) {
        this.$store.dispatch(UserSourceActionTypes.UPDATE_USER_SOURCE, userSourceParams);
      }

      if(this.$route.query.friend_code){
        this.$store.dispatch(AuthActionTypes.SET_FRIEND_CODE, this.$route.query.friend_code);
      }

      this.$store.dispatch(UserActionTypes.UPDATE_MINIGAMES);
      if (this.isLoggedIn) {
        this.$store.dispatch(AuthActionTypes.UPDATE_USER_DATA, false);
        this.$store.dispatch(UserActionTypes.UPDATE_USER_WALLET);
        this.$store.dispatch(UserActionTypes.UPDATE_USER_GAMES);
        if (!this.isNativeApp) {
          this.updateFirebaseToken();
        }
        this.initNotificationCenter();
        this.sendLegacyNativeAppEvent(TO_NATIVE_APP_EVENTS.LOGIN);
        this.sendAnalyticsCRMUserId();
      }

      this.initChatConnectionChangedListener();
      this.initAppRequest();

      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          if (reloadTime.isAfter(moment.utc())) {
            this.initChatConnectionChangedListener();
            isChatConnected().then(isConnected => {
              if (isConnected === false) {
                if (navigator.onLine) {
                  location.reload();
                }
              } else {
                this.initAppRequest();
              }
            }).catch(() => {
              location.reload();
            });
          } else {
            location.reload();
          }
        }
      });

      window.addEventListener('resize', debounce(() => this.$store.dispatch(AppActionTypes.SET_DEVICE, getDevice())));
      window.addEventListener('appinstalled', () => this.$store.dispatch(AppActionTypes.SET_IS_PWA_INSTALLED, true));

      if (this.maintenanceMode && this.$route.name !== ROUTES.maintenanceMode.name) {
        this.routerPush(ROUTES.maintenanceMode.name);
      }
    },
    computed: {
      showSidebar(): boolean {
        return !HIDE_NAVIGATION_PAGES[this.$route.name];
      },
      showTabBar(): boolean {
        return !HIDE_TAB_BAR_PAGES[this.$route.name];
      },
      showGroupLogo(): boolean {
        return !HIDE_GROUP_LOGO_PAGES[this.$route.name];
      },
      maintenanceMode(): boolean {
        return this.$store.getters.maintenanceMode;
      },
      appVersion(): string {
        return this.$store.getters.appVersion;
      },
    },
    methods: {
      updateFirebaseToken() {
        canNotify().then(canNotifyUser => {
          if (canNotifyUser) {
            getToken()
              .then(token => this.$store.dispatch(AuthActionTypes.UPDATE_FIREBASE_TOKEN, {userId: this.userId, token}))
              .catch(() => {
                setTimeout(() => {
                  getToken()
                    .then(token => this.$store.dispatch(AuthActionTypes.UPDATE_FIREBASE_TOKEN, {userId: this.userId, token}))
                    .catch(() => {/** Nothing to do */});
                }, 10000);
              });
          }
        });
      },
      async initChatListener() {
        try {
          this.stopChatListener();
          if (this.userId) {
            lastMessagesUnsubscribe = await onUserLastMessagesChange(this.userId, lastMessages => {
              const messages = lastMessages || [];
              this.$store.dispatch(UserActionTypes.UPDATE_USER_CHAT_LAST_MESSAGES, messages);
              const unreadMessage = messages.find(({isRead}) => isRead === false);
              this.$store.dispatch(UserActionTypes.UPDATE_USER_CHAT_HAS_UNREAD_LAST_MESSAGES, Boolean(unreadMessage));
            });
            competitionsLastMessagesUnsubscribe = await onUserCompetitionsLastMessagesChange(this.userId, lastMessages => {
              const messages = lastMessages || [];
              this.$store.dispatch(UserActionTypes.UPDATE_USER_CHAT_COMPETITIONS_LAST_MESSAGES, messages);
              const unreadMessage = messages.find(({isRead}) => isRead === false);
              this.$store.dispatch(UserActionTypes.UPDATE_USER_CHAT_HAS_UNREAD_COMPETITIONS_LAST_MESSAGES, Boolean(unreadMessage));
            });
          }
        } catch (e) {
          setTimeout(() => this.initChatListener(), 10000);
        }
      },
      stopChatListener() {
        if (lastMessagesUnsubscribe) {
          lastMessagesUnsubscribe();
          lastMessagesUnsubscribe = null;
        }
        if (competitionsLastMessagesUnsubscribe) {
          competitionsLastMessagesUnsubscribe();
          competitionsLastMessagesUnsubscribe = null;
        }
      },
      initNotificationCenter() {
        this.stopNotificationCenterListener();
        this.updateNotificationCenter();
        notificationCenterInterval = setInterval(() => {
          this.updateNotificationCenter();
        }, 30000);
      },
      updateNotificationCenter() {
        this.$store.dispatch(NotificationCenterActionTypes.UPDATE_NOTIFICATION_CENTER);
      },
      stopNotificationCenterListener() {
        if (notificationCenterInterval) {
          clearInterval(notificationCenterInterval);
          notificationCenterInterval = null;
        }
      },
      initAppRequest() {
        if (!this.isNativeApp || this.appVersion) {
          this.$store.dispatch(AppActionTypes.INIT_APP);
        }
      },
      async initChatConnectionChangedListener() {
        await this.$store.dispatch(UserActionTypes.UPDATE_CHAT_CONNECTED, false);
        this.stopChatConnectionChangedListener();
        const onConnectionChangedRetry = () => {
          onChatConnectionChangedListener = onConnectionChanged(isConnected => {
            if (isConnected) {
              this.initChatListener();
            } else {
              this.stopChatListener();
            }

            this.$store.dispatch(UserActionTypes.UPDATE_CHAT_CONNECTED, isConnected);
          }, () => setTimeout(() => onConnectionChangedRetry(), 1000));

          if (!onChatConnectionChangedListener) {
            setTimeout(() => onConnectionChangedRetry(), 1000);
          }
        };

        onConnectionChangedRetry();
      },
      stopChatConnectionChangedListener() {
        if (onChatConnectionChangedListener) {
          onChatConnectionChangedListener();
          onChatConnectionChangedListener = null;
        }
      },
      showCookieConsent() {
        cookieConsentRun(this.currentLang, {
          "i_agree_button_clicked": this.sendCookieConsentUpdateEvent,
          "i_decline_button_clicked": this.sendCookieConsentUpdateEvent,
          "preferences_center_save_pressed": this.sendCookieConsentUpdateEvent,
        });

      },
      initCookieConsentInitialData() {
        if (!hasUserDefaultCookieConsentValues()) {
          this.sendCookieConsentUpdateEvent(true);
        }
      },
      async sendCookieConsentUpdateEvent(isInit = false) {
        const values = await getCurrentCookieConsentValues(isInit);
        if (Object.keys(values).length > 0) {
          this.pushCookieConsentDataLayer('consent', 'update', values);
          this.sendAnalyticsEvent(CONSENT_UPDATE_EVENT_NAME, values);
        }

        if (!isInit) {
          this.$store.dispatch(AuthActionTypes.ACCEPT_COOKIES);
        }
      },
    },
    watch: {
      userId() {
        this.initChatConnectionChangedListener();
        if (this.userId) {
          if (!this.isNativeApp) {
            this.updateFirebaseToken();
          }
          this.initNotificationCenter();
          this.initAppRequest();
          this.sendAnalyticsCRMUserId();
        } else {
          this.stopChatListener();
          this.stopNotificationCenterListener();
        }
      },
      currentLang() {
        this.showCookieConsent();
      },
      maintenanceMode() {
        if (this.maintenanceMode) {
          if (this.$route.name !== ROUTES.maintenanceMode.name) {
            this.routerPush(ROUTES.maintenanceMode.name);
          }
        } else {
          if (this.$route.name === ROUTES.maintenanceMode.name) {
            this.routerPush(ROUTES.home.name);
            location.reload();
          }
        }
      },
      appVersion() {
        this.initAppRequest();
      },
    }
  });
