import { CurrencyType, getDevice, isPwaInstalled } from "@/utils/common";
import { BreadcrumbItemType } from "@/utils/breadcrumb";
import { hasAds } from '@/services/ads';

const state = {
  loading: false,
  theme: {},
  config: {
    id: 0,
    alias: null as string,
    usersLastMessagesKeyPrefix: '',
    supportEmail: null as string,
    allowDeposit: false,
    currency: {
      symbol: '€',
      isoCode: 'EUR',
      multiplier: 1,
      decimals: 2,
      symbolPosRight: false,
      icon: null,
    } as CurrencyType,
  },
  device: getDevice() as { isDesktop: boolean, isTablet: boolean, isMobile: boolean },
  modalProps: {},
  nextUrl: '',
  pageData: {},
  breadcrumb: [] as BreadcrumbItemType[],
  isPwaInstalled: isPwaInstalled(),
  game: Object,
  topBannerHeight: 0,
  bottomBannerHeight: 0,
  adsLoaded: false,
  showAds: hasAds,
  maintenanceMode: false,
  appVersion: null as string,
};

export type AppState = typeof state;

export default state;
